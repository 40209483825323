import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { previewBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';

export type FragmentCheckoutOptionsType = Document & {
	_type: 'FragmentCheckoutOptions';
	title: string;
	content: InlineContentType;
	ctaText: string;
	optionsTitle: string;
	helperText: string;
	footerText?: string;
	v2: {
		title: string;
		content: InlineContentType;
		optionsTitle: string;
	};
};

export default defineType({
	name: 'FragmentCheckoutOptions',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'optionsTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'helperText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'footerText',
			type: 'string',
		},
		{
			name: 'v2',
			type: 'object',
			validation: (r) => r.required(),
			options: { collapsible: true },
			fields: [
				{
					name: 'title',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'optionsTitle',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
	],
	preview: previewBlockContent(),
});

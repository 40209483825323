import { HiOutlinePlay } from '@react-icons/all-files/hi/HiOutlinePlay';
import { defineField, defineType } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { InlineContentType } from '../block/InlineContent';
import { HelpOverpanelSectionType } from '../HelpOverpanelSection';
import { FlowBaseStepType } from './baseTypes';

export type FlowFullScreenVideoStepType = FlowBaseStepType & {
	_type: 'FlowFullScreenVideoStep';
	title?: InlineContentType;
	description?: InlineContentType;
	overpanelHelpSection: HelpOverpanelSectionType;
	videoUri: string;
	contentOnDark: boolean;
};

export default defineType({
	name: 'FlowFullScreenVideoStep',
	type: 'document',
	icon: HiOutlinePlay,
	fields: [
		defineField({
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		}),
		defineField({
			name: 'description',
			type: 'InlineContent',
		}),
		defineField({
			name: 'overpanelHelpSection',
			type: 'reference',
			to: [{ type: 'HelpOverpanelSection' }],
			validation: (r) => r.required(),
		}),
		defineField({
			name: 'videoUri',
			description: 'The video uri to be played, needs to be MP4',
			type: 'string',
		}),
		defineField({
			name: 'contentOnDark',
			description: 'If true, the content will be light on dark background',
			type: 'boolean',
			initialValue: true,
		}),
	],
	preview: previewNamedBlockContent('title'),
});
